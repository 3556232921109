
@font-face {
  font-family: 'lovely_eunike_hans';
  src: url('/public/lovely_eunike_hans.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-image: linear-gradient(to right top, #ff89b5, #ff99c8, #ffa8db, #ffb8ee, #c7caff);
  color: #fff;
  font-family: 'Dancing Script', cursive;
}
body{
  background-image: linear-gradient(to right top, #ff89b5, #ff99c8, #ffa8db, #ffb8ee, #c7caff);
  font-family: 'lovely_eunike_hans', sans-serif;
}

.App-header {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 4vmin);
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
}

.content {
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: row;
  gap:2rem;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  text-align: center;

}

.footer {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  color: #fff;
  padding: 1rem;
  text-align: center;
  margin-top: auto;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.3);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(1);
  }
}

.heart {
  animation: heartbeat 2s infinite;
}




@keyframes jiggle {
  0%, 100% { transform: rotate(0); }
  25% { transform: rotate(5deg); }
  75% { transform: rotate(-5deg); }
}

.jiggle img {
  height: 8rem;
  animation: jiggle 0.5s infinite;
}
@keyframes jiggleReverse {
  0%, 100% { transform: rotate(0); }
  25% { transform: rotate(-10deg); }
  75% { transform: rotate(10deg); }
}

.jiggleReverse img {
  height: 8rem;
  margin-left: auto;
  animation: jiggleReverse 0.5s infinite;
}

.jigg {
  height: 8rem;
  margin-left: auto;
  animation: jiggleReverse 0.5s infinite;
}

.jiggleReverseFast img {
  height: 8rem;
  margin-left: auto;
  animation: jiggleReverse 0.3s infinite;
}